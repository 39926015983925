// /web/src/templates/category.js
import React from 'react'
import {graphql, Link} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogPostPreview from '../components/blog-post-preview'
import SEO from '../components/seo'
import MauticForm from '../components/mautic-form'
import Layout from '../containers/layout'
// Import a function to build the blog URL
import {getBlogUrl} from '../lib/helpers'

import {responsiveTitle1} from '../components/typography.module.css'

import styles from '../components/blog-post-preview-grid.module.css'


// Add “posts” to the GraphQL query
export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCategory(id: {eq: $id}) {
      title
      titleLong
      description
      posts {
        _id
        title
        publishedAt
        slug {
          current
        }
        mainImage {
          ...SanityImage
          alt
        }
        _rawExcerpt
      }
    }
  }
`
const CategoryPostTemplate = props => {
  const {data = {}, errors} = props
  // Destructure the new posts property from props
  const {title, titleLong, slug, description, posts} = data.category || {}

  return (
    <Layout>
      <Container>
        {errors && <GraphQLErrorList errors={errors} />}
        {!data.category && <p>No category data</p>}
        <SEO title={titleLong} description={description} />
        <h1 className={responsiveTitle1}>{title}</h1>
        <p>{description}</p>

        <MauticForm site="healthyafternoonsnacks" title={title} source_slug={slug}/>

        <div className={styles.root}>
        {props.title && <h2 className={styles.headline}>{props.title}</h2>}
        <ul className={styles.grid}>
            {posts &&
            posts.map(post => (
                <li key={post._id}>
                <BlogPostPreview {...post} />
                </li>
            ))}
        </ul>
        {props.browseMoreHref && (
            <div className={styles.browseMoreNav}>
            <Link to={props.browseMoreHref}>Browse more</Link>
            </div>
        )}
        </div>
      </Container>
    </Layout>
  )
}

export default CategoryPostTemplate