import React from 'react'
import PropTypes from 'prop-types'

import styles from './mautic-form.module.css'

function MauticForm ({site, title, source_slug}) {
  return (




    <div id="mauticform_wrapper_signupform" className={styles.wrapper}>
        <h2>Fresh Snack Ideas Daily</h2>
        <form autoComplete="false" role="form" method="post" action="https://pages.instaestimate.com/form/submit?formId=6" id="mauticform_signupform" data-mautic-form="signupform" encType="multipart/form-data">
            <div className="mauticform-error" id="mauticform_signupform_error"></div>
            <div className="mauticform-message" id="mauticform_signupform_message"></div>
            <div className="mauticform-innerform">

                
                <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                    <input id="mauticform_input_signupform_site_name" name="mauticform[site_name]" value={site} className="mauticform-hidden" type="hidden" />
                <div id="mauticform_signupform_f_name" className="mauticform-row mauticform-text mauticform-field-2">
                    <label id="mauticform_label_signupform_f_name" for="mauticform_input_signupform_f_name" className="mauticform-label">Your Name</label>
                    <input id="mauticform_input_signupform_f_name" name="mauticform[f_name]" className="mauticform-input" type="text"/>
                    <span className={styles.errormsg}></span>
                </div>

                <div id="mauticform_signupform_email" className="mauticform-row mauticform-email mauticform-field-3">
                    <label id="mauticform_label_signupform_email" for="mauticform_input_signupform_email" className="mauticform-label">Your Email</label>
                    <input id="mauticform_input_signupform_email" name="mauticform[email]" className="mauticform-input" type="email"/>
                    <span className={styles.errormsg}></span>
                </div>

                <div id="mauticform_signupform_mobile_phone" className="mauticform-row mauticform-tel mauticform-field-4">
                    <label id="mauticform_label_signupform_mobile_phone" for="mauticform_input_signupform_mobile_phone" className="mauticform-label">Mobile Phone</label>
                    <input id="mauticform_input_signupform_mobile_phone" name="mauticform[mobile_phone]" className="mauticform-input" type="tel"/>
                    <span className={styles.errormsg}></span>
                </div>

                <div id="mauticform_signupform_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-5">
                    <button type="submit" name="mauticform[submit]" id="mauticform_input_signupform_submit" className="mauticform-button btn btn-default">Submit</button>
                </div>
                </div>
            </div>

            <input type="hidden" name="mauticform[formId]" id="mauticform_signupform_id" value="6"/>
            <input type="hidden" name="mauticform[return]" id="mauticform_signupform_return" value=""/>
            <input type="hidden" name="mauticform[formName]" id="mauticform_signupform_name" value="signupform"/>

            </form>
    </div>
  )
}

MauticForm.defaultProps = {
    site: ""
}

MauticForm.propTypes = {
  site: PropTypes.string.isRequired
}

export default MauticForm
